import React, { useEffect, useRef } from "react";
import { getAttributes, setCustomAttributes } from "../../common/common";
import { TNclInputType, TInputMultiLineMode } from "../../common/communication.base";
import { NclLocatorPanel } from "../../common/components.ncl";
import { setRequestedActiveControl } from "../app";
import css from "./Input.scss";
import { InnerInputProps, InputHtmlAttributes, createHint } from "./utils";

export default function K2InnerInput(props: InnerInputProps) {
  const element = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (props.blockSoftKeyboard) {
      element.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (props.focus) {
      element.current?.focus();
    }
  }, [props.focus]);

  useEffect(() => {
    //Po změně dat by se jinak přesunul kurzor na konec inputu
    if (props.data.PasteText && props.data.PasteText.length > 0) {
      if (props.lastSelectedPosition?.current != undefined) {
        element.current?.setSelectionRange(props.lastSelectedPosition.current.start, props.lastSelectedPosition.current.end);
        element.current?.focus(); // Např. Multiformat (po kliku na button a vložení textu je žádoucí kurzor a focus na inputu)
      }
    }
  }, [props.value]);

  function needKeyStopPropagation(e: React.KeyboardEvent<HTMLElement>) {
    // zachytávám použitou(již aplikovanou) klávesu, aby dále neprobublávala DOM stromem
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "ArrowLeft" && !e.altKey) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "ArrowRight" && !e.altKey) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "c" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "Insert" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "v" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }
    if (e.shiftKey && e.key === "Insert" && !(e.ctrlKey || e.shiftKey)) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "x" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "a" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "z" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "y" && !(e.altKey || e.shiftKey)) {
      e.stopPropagation();
    }

    if (e.key === "Backspace" && !(e.altKey || e.shiftKey || e.ctrlKey)) {
      e.stopPropagation();
    }

    if (e.key === "Delete" && !(e.altKey || e.shiftKey || e.ctrlKey)) {
      e.stopPropagation();
    }

    if (!props.control.State.ReadOnly && e.code.includes("Numpad") && e.code !== "NumpadEnter") {
      e.stopPropagation();
    }
  }

  function refCallBack(el: HTMLInputElement | HTMLTextAreaElement | null) {
    if (!el) return;

    setCustomAttributes(el, props.control.Ncl.Attributes);
    element.current = el;
    props.initRef(el);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.change) {
      const value = e.target.value;

      if (props.value != value) {
        props.setValue(value);
        props.change(value);
      }
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }

    needKeyStopPropagation(e); // catched used key in input, that i can't use in other parts of application

    if (!e.isPropagationStopped()) {
      if (((e.ctrlKey || e.metaKey) && e.key === "ArrowUp") || ((e.ctrlKey || e.metaKey) && e.key === "ArrowDown")) {
        props.control.nextPriorValue(e.key === "ArrowDown" ? true : false, props.value);
        e.preventDefault();
      }

      if (
        (e.shiftKey && e.key === "Enter") ||
        (isTextArea() && (e.key === "ArrowUp" || e.key === "ArrowDown")) ||
        (props.data.MultiLineMode === TInputMultiLineMode.imlmMultiLineFull && e.key === "Enter" && !e.ctrlKey)
      ) {
        e.stopPropagation();
      } else if (e.key === "Enter") {
        props.control.change(props.value, false); //set value for send, but send key Enter
      }
    }
  }

  function handleFocus(e: React.FocusEvent<any>) {
    if (props.onFocus) {
      props.onFocus(e);
    }

    if (!(props.control.Parent instanceof NclLocatorPanel)) {
      if (props.lastSelectedPosition?.current != undefined && isTextArea()) {
        element.current?.setSelectionRange(props.lastSelectedPosition.current.start, props.lastSelectedPosition.current.end);
      } else {
        // Input se při focusu vybírá vždy celý
        element.current?.select();
      }
    }
  }

  function handleBlur(e: React.FocusEvent<any>) {
    props.lastSelectedPosition.current = { start: element.current.selectionStart, end: element.current.selectionEnd }; // Pro případ že se bude provádět akce mimo input pracující s PasteText (např. klik na button
    if (props.onBlur) {
      props.onBlur(e);
    }
    setRequestedActiveControl(e, props.control.getRealizerUID());
    props.control.change(props.value, true);
  }

  function handleClick() {
    props.lastSelectedPosition.current = { start: element.current.selectionStart, end: element.current.selectionEnd }; // Pro případ že se bude provádět akce mimo input pracující s PasteText (např. klik na button
  }

  function isTextArea(): boolean {
    if (props.control.State.InputType === TNclInputType.nitString && props.data.MultiLineMode) return true;
    return false;
  }

  const value: string = props.value;
  const inputAttributes: InputHtmlAttributes = {
    type: "text",
    id: props.control.Ncl.Name,
    title: createHint(props.data.Hint, value),
    ...getAttributes(props.data),
  };
  const inputClass: string = css.in_input + " " + props.className;

  inputAttributes.autoComplete = "off";
  inputAttributes.placeholder = props.data.Watermark;
  if (props.data.MaxLength) {
    inputAttributes.maxLength = props.data.MaxLength;
  }

  if (props.control.Ncl.FrgtData.IsPassword) {
    inputAttributes.type = props.revealPassword ? "text" : "password";
    inputAttributes.title = undefined;
  }

  if (isTextArea()) {
    return (
      <textarea
        className={inputClass}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        ref={refCallBack}
        {...inputAttributes}
        value={value}
        rows={props.data.MultiLineMode === TInputMultiLineMode.imlmMultiLineSimple ? props.control.Ncl.FrgtData.Size : 0}
      />
    );
  } else {
    return (
      <input
        className={inputClass}
        value={value}
        ref={refCallBack}
        onKeyDown={handleKeyDown}
        onPaste={props.onPaste}
        onDrop={props.onDrop}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        {...inputAttributes}
      />
    );
  }
}
