import React, { useEffect, useMemo, useRef, useState } from "react";
import { base64ToBlob, CancelablePromise, isPDF, isUrl, Log, makeCancelable } from "../../common/common";
import { MediaData, MediaManager } from "../../common/mediaManager";
import K2TruncateText from "../Text/K2TruncateText";
import { Context, __ } from "../../appcontext";
import K2PdfViewer from "./K2PdfViewer";

export interface FileViewerProps {
  url: string;
}

const urlPostfix = "?page=1&pagemode=none&toolbar=0&statusbar=0&navpanes=0";

function isIOS(): boolean {
  return Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS || Context.DeviceInfo.IsMacOS;
}

const K2FileViewer = (props: FileViewerProps) => {
  const promise = useRef<CancelablePromise<MediaData>>();
  const [data, setData] = useState("");
  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    initData();
  }, []);

  const blobUrl = useMemo(() => {
    if (data === "") return;

    const base64String = data.split(",")[1];
    const blob = base64ToBlob(base64String, "application/pdf");
    const url = URL.createObjectURL(blob);

    return url;
  }, [data]);

  const initData = () => {
    if (isUrl(props.url)) {
      setData(props.url);
      setUrl(props.url + (isPDF(props.url) ? urlPostfix : ""));
    } else {
      promise.current = makeCancelable<MediaData>(MediaManager.tryGetData(props.url));

      promise.current.promise
        .then((data) => {
          if (data.errorMessage) {
            setErrorMessage(data.errorMessage);
            setUrl(props.url);
          } else {
            setData(data.base64Data);
            setUrl(props.url);
          }
        })
        .catch((reason) => {
          Log.error("error: " + reason, null);
          setErrorMessage(__("fileDownloadNotSuccess"));
        });
    }
  };

  // Chyba při stahování dat
  if (errorMessage) {
    return (
      <K2TruncateText line={10} style={{ alignSelf: "center" }}>
        {errorMessage}
      </K2TruncateText>
    );
  }

  // Pokud je PDF
  if (isPDF(props.url)) {
    if (navigator.pdfViewerEnabled && !isIOS()) {
      return <object data={blobUrl} className="file_object" />;
    } else {
      return <K2PdfViewer data={data.split(",")[1]} url={blobUrl} fileName={url} />;
    }
  }

  //Pokud je cokoliv jiného
  return <object data={data} className="file_object" />;
};

export default K2FileViewer;
